import { render, staticRenderFns } from "./undertaking.vue?vue&type=template&id=740af6f5&scoped=true&"
import script from "./undertaking.vue?vue&type=script&lang=js&"
export * from "./undertaking.vue?vue&type=script&lang=js&"
import style0 from "./undertaking.vue?vue&type=style&index=0&id=740af6f5&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "740af6f5",
  null
  
)

export default component.exports